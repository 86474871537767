
  import { Component, Vue } from "vue-property-decorator";
  import LzBox from "@/components/Box.vue";
  import { namespace } from "vuex-class";
  import Chart, { ChartConfiguration } from "chart.js/auto";
  import apiData from "../api";
  const auth = namespace("auth");

  Chart.register();

  @Component({ components: { LzBox } })
  export default class Read extends Vue {
    @auth.State("id")
    public ongId!: string;

    incomesBarData: any[] = [];
    incomesBarLabels: any[] = [];
    incomesDoughnoutData: any[] = [];
    donationsDoughnoutData: any[] = [];

    coursesCount = 0;
    donationsCount = 0;
    eventsCount = 0;
    projectsCount = 0;
    subscriptionsCount = 0;

    currentMonth = 0;

    async mounted() {
      await apiData.getDashboard(this.ongId).then(({ data }: any) => {
        this.coursesCount = +data.countOfCourses;
        this.donationsCount = +data.countOfDonations;
        this.eventsCount = +data.countOfEvents;
        this.projectsCount = +data.countOfProjects;
        this.subscriptionsCount = +data.countOfSubscriptions;
      });
      await apiData.getStatistics(this.ongId).then(({ data }: any) => {
        const fullYear = new Date().getFullYear();
        const month = new Date().getMonth();

        for (const year of [fullYear - 1, fullYear]) {
          for (const [monthName, monthValue] of Object.entries(data[year])) {
            this.incomesBarData.push((monthValue as any).totalAmount);
            this.incomesBarLabels.push(`${monthName.substring(0, 3)} ${year}`);
          }
        }

        this.incomesDoughnoutData.push(
          ...[
            data.incomes.incomesProjects,
            data.incomes.incomesEvents,
            data.incomes.incomesCourses,
            data.incomes.incomesSubscriptions,
            data.incomes.incomesDonations
          ]
        );

        this.currentMonth = (Object.entries(data[fullYear])[month][1] as any)
          .totalAmount as number;
        //labels: ["Proyectos", "Eventos", "Cursos", "Socios", "Donaciones"],

        this.donationsDoughnoutData.push(
          ...[
            data.donations.projects,
            data.donations.events,
            data.donations.courses,
            data.donations.subscriptions,
            data.donations.donations
          ]
        );
      });

      // incomes bar chart
      const ctx = (document.getElementById(
        "incomesBar"
      ) as HTMLCanvasElement)?.getContext("2d");

      if (!ctx) return;

      new Chart(ctx, {
        type: "bar",
        legend: {
          display: false
        },
        data: {
          labels: this.incomesBarLabels,
          title: null,
          datasets: [
            {
              data: this.incomesBarData,
              backgroundColor: ["#8D00D8"],
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      } as ChartConfiguration);

      const ctxIncomesDonut = (document.getElementById(
        "incomesDonut"
      ) as HTMLCanvasElement)?.getContext("2d");

      if (!ctxIncomesDonut) return;

      new Chart(ctxIncomesDonut, {
        type: "doughnut",
        data: {
          labels: [
            this.$t("data.read.labels.projects"),
            this.$t("data.read.labels.events"),
            this.$t("data.read.labels.courses"),
            this.$t("data.read.labels.partners"),
            this.$t("data.read.labels.donations")
          ],
          datasets: [
            {
              data: this.incomesDoughnoutData,
              backgroundColor: [
                "#EB2873",
                "#FF4863",
                "#FF7456",
                "#0CF2B4",
                "#8D00D8"
              ],
              borderColor: [
                "#EB2873",
                "#FF4863",
                "#FF7456",
                "#0CF2B4",
                "#8D00D8"
              ],
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
                padding: 20,
                font: {
                  size: 12
                }
              }
            },
            paddingBelowLegends: false
          }
        }
      } as ChartConfiguration);

      const ctxDonationsDonut = (document.getElementById(
        "donationsDonut"
      ) as HTMLCanvasElement)?.getContext("2d");

      if (!ctxDonationsDonut) return;

      new Chart(ctxDonationsDonut, {
        type: "doughnut",
        data: {
          labels: [
            this.$t("data.read.labels.projects"),
            this.$t("data.read.labels.events"),
            this.$t("data.read.labels.courses"),
            this.$t("data.read.labels.partners"),
            this.$t("data.read.labels.donations")
          ],
          datasets: [
            {
              data: this.donationsDoughnoutData,
              backgroundColor: [
                "#EB2873",
                "#FF4863",
                "#FF7456",
                "#0CF2B4",
                "#8D00D8"
              ],
              borderColor: [
                "#EB2873",
                "#FF4863",
                "#FF7456",
                "#0CF2B4",
                "#8D00D8"
              ],
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
                padding: 10,
                font: {
                  size: 10
                }
              }
            },
            paddingBelowLegends: false
          }
        }
      } as ChartConfiguration);
    }
  }
